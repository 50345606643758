// Input
.input {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;

  &__text {
    background: $background-primary;
    border-radius: $border-radius;
    padding: $base-spacing 15px $base-spacing 55px;
    font-size: $font-small-size;
    font-weight: $font-weight-bold;
    border: $border;
    outline: none;
    width: 100%;
    color: $text-primary;
    font-family: $font-family;
    position: relative;

    @include larger-than(mobile) {
      font-size: $font-base-size;
      min-width: $input-container-width;
      padding: $base-spacing 25px $base-spacing 75px;
    }
  }

  &__asset {
    position: absolute;
    top: 17px;
    left: 15px;
    width: 28px;
    height: 30px;
    z-index: 5;
    opacity: 0.6;

    @include larger-than(mobile) {
      left: $base-spacing;
      top: 15px;
      width: 35px;
      height: 2 * $base-spacing;
    }
  }
}
