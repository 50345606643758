// Error
.error {
  position: relative;
  width: 100%;

  &__container {
    align-items: center;
    background: $error-color;
    border: $border;
    border-radius: $border-radius;
    padding: ($base-spacing + 5px);
  }

  &__message {
    color: $text-primary;
    font-size: $font-extra-small-size;
    font-weight: $font-weight-bold;
    line-height: 1.6;

    @include larger-than(mobile) {
      font-size: $font-small-size;
    }
  }
}
