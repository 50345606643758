/*! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
/* Document (https://html.spec.whatwg.org/multipage/semantics.html#semantics)
   ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */
*,
::before,
::after {
  background-repeat: no-repeat; /* 1 */
  box-sizing: inherit; /* 2 */
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritence in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit; /* 1 */
  vertical-align: inherit; /* 2 */
}

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Add the default cursor in all browsers (opinionated).
 * 3. Prevent font size adjustments after orientation changes in IE and iOS.
 */
html {
  box-sizing: border-box; /* 1 */
  cursor: default; /* 2 */
  -ms-text-size-adjust: 100%; /* 3 */
  -webkit-text-size-adjust: 100%; /* 3 */
}

/* Sections (https://html.spec.whatwg.org/multipage/semantics.html#sections)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/* Grouping content (https://html.spec.whatwg.org/multipage/semantics.html#grouping-content)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in IE.
 */
figcaption,
figure,
main { /* 1 */
  display: block;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics (https://html.spec.whatwg.org/multipage/semantics.html#text-level-semantics)
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent; /* 1 */
  -webkit-text-decoration-skip: objects; /* 2 */
}

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/*
 * Remove the text shadow on text selections (opinionated).
 * 1. Restore the coloring undone by defining the text shadow (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc; /* 1 */
  color: #000000; /* 1 */
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc; /* 1 */
  color: #000000; /* 1 */
  text-shadow: none;
}

/* Embedded content (https://html.spec.whatwg.org/multipage/embedded-content.html#embedded-content)
   ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg {
  fill: currentColor;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Tabular data (https://html.spec.whatwg.org/multipage/tables.html#tables)
   ========================================================================== */
/**
 * Collapse border spacing
 */
table {
  border-collapse: collapse;
}

/* Forms (https://html.spec.whatwg.org/multipage/forms.html#forms)
   ========================================================================== */
/**
 * Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  margin: 0;
}

/**
 * Inherit styling in all browsers (opinionated).
 */
button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input { /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select { /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button; /* 2 */
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  overflow: auto; /* 1 */
  resize: vertical; /* 2 */
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive elements (https://html.spec.whatwg.org/multipage/forms.html#interactive-elements)
   ========================================================================== */
/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */
details,
menu {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Scripting (https://html.spec.whatwg.org/multipage/scripting.html#scripting-3)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* User interaction (https://html.spec.whatwg.org/multipage/interaction.html#editing)
   ========================================================================== */
/*
 * Remove the tapping delay on clickable elements (opinionated).
 * 1. Remove the tapping delay in IE 10.
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation; /* 1 */
  touch-action: manipulation;
}

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

/* ARIA (https://w3c.github.io/html-aria/)
   ========================================================================== */
/**
 * Change the cursor on busy elements (opinionated).
 */
[aria-busy=true] {
  cursor: progress;
}

/*
 * Change the cursor on control elements (opinionated).
 */
[aria-controls] {
  cursor: pointer;
}

/*
 * Change the display on visually hidden accessible elements (opinionated).
 */
[aria-hidden=false][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements (opinionated).
 */
[aria-disabled] {
  cursor: default;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
}

a {
  color: inherit;
}

.textreplace {
  display: block;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.textreplace-sr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.fullsize {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/*https://gist.github.com/terkel/4373420#gistcomment-1626534*/
body {
  background: #292929;
}

.app {
  background: #292929;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 20px;
}
@media (min-width: 48rem) {
  .app {
    justify-content: center;
    height: 100%;
  }
}
@media (min-width: 48rem) {
  .app--opened {
    height: auto;
  }
}
.app__row {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}
@media (min-width: 48rem) {
  .app__row {
    width: 100%;
    max-width: 700px;
    flex-direction: row;
  }
}
.app__column {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
}
@media (min-width: 48rem) {
  .app__column {
    width: 700px;
  }
}
.app__column--invoice-loaded {
  margin-top: 0;
}
.app__column--error {
  margin-top: 0;
}
.app__submit {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 20px;
}
@media (min-width: 48rem) {
  .app__submit {
    margin-top: 0;
    padding-left: 10px;
  }
}
.app__submit--invoice-loaded {
  position: absolute;
  margin-top: 0;
  right: 0;
  top: 7px;
}

.error {
  position: relative;
  width: 100%;
}
.error__container {
  align-items: center;
  background: #3e0203;
  border: 1px solid #212020;
  border-radius: 4px;
  padding: 25px;
}
.error__message {
  color: #f3f3f3;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.6;
}
@media (min-width: 48rem) {
  .error__message {
    font-size: 18px;
  }
}

.input {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
}
.input__text {
  background: #000000;
  border-radius: 4px;
  padding: 20px 15px 20px 55px;
  font-size: 18px;
  font-weight: 900;
  border: 1px solid #212020;
  outline: none;
  width: 100%;
  color: #f3f3f3;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  position: relative;
}
@media (min-width: 48rem) {
  .input__text {
    font-size: 24px;
    min-width: 540px;
    padding: 20px 25px 20px 75px;
  }
}
.input__asset {
  position: absolute;
  top: 17px;
  left: 15px;
  width: 28px;
  height: 30px;
  z-index: 5;
  opacity: 0.6;
}
@media (min-width: 48rem) {
  .input__asset {
    left: 20px;
    top: 15px;
    width: 35px;
    height: 40px;
  }
}

.invoice {
  background: #000000;
  border-radius: 4px;
  width: 100%;
  margin: 0 auto;
  padding: 10px 0;
  opacity: 0;
  visibility: hidden;
  border: 1px solid #212020;
  margin-bottom: 20px;
}
.invoice--opened {
  opacity: 1;
  visibility: visible;
}
.invoice__item {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 48rem) {
  .invoice__item {
    flex-direction: row;
  }
}
.invoice__item--nested {
  flex-direction: column;
}
.invoice__item-title {
  color: #838383;
  font-weight: 900;
  min-width: 200px;
  padding-bottom: 5px;
}
@media (min-width: 48rem) {
  .invoice__item-title {
    padding-bottom: 0;
  }
}
.invoice__item-value {
  font-weight: 900;
  color: #f3f3f3;
  width: 100%;
  word-break: break-all;
}
.invoice__item-value--nested {
  padding: 10px 0;
}
.invoice__item-value--nested:last-child {
  padding-bottom: 0;
}
.invoice__nested-item {
  padding: 5px 0;
  display: flex;
  flex-direction: row;
  padding-left: 20px;
}
.invoice__nested-title {
  font-size: 13px;
  color: #838383;
  font-weight: 900;
  min-width: 180px;
}
.invoice__nested-value {
  font-weight: 900;
  color: #f3f3f3;
  width: 100%;
}

.logo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 25px;
}
@media (min-width: 48rem) {
  .logo {
    align-items: center;
    padding: 40px 0;
    padding-bottom: 40px;
    margin-bottom: 45px;
  }
}
.logo__title {
  color: #f3f3f3;
  font-size: 24px;
  font-weight: 900;
}
@media (min-width: 48rem) {
  .logo__title {
    font-size: 60px;
  }
}
.logo__subtitle {
  text-align: left;
  color: #979797;
  padding-top: 20px;
  font-weight: 900;
  font-size: 14px;
}
@media (min-width: 48rem) {
  .logo__subtitle {
    text-align: center;
    font-size: 24px;
  }
}
.logo__subtitle-small {
  display: block;
  font-size: 12px;
  color: #f3f3f3;
  padding-top: 10px;
  font-weight: 900;
}
@media (min-width: 48rem) {
  .logo__subtitle-small {
    font-size: 16px;
  }
}

.submit {
  height: 72px;
  width: 72px;
  border-radius: 50%;
  background: #000000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #212020;
  cursor: pointer;
  z-index: 3;
}
@media (min-width: 64rem) {
  .submit {
    border-radius: 50%;
  }
}
.submit:hover .submit__asset {
  opacity: 1;
}
.submit__asset {
  width: 60%;
  opacity: 0.6;
}
.submit__close {
  border-radius: unset;
  border: 0;
  height: 40px;
  width: 40px;
  top: 10px;
}
@media (min-width: 48rem) {
  .submit__close {
    margin-top: 4px;
    border-radius: 50%;
    margin-right: 1px;
  }
}

.options {
  position: absolute;
  bottom: 20px;
  right: 20px;
  left: 20px;
}
@media (min-width: 48rem) {
  .options {
    top: 20px;
    right: 20px;
    bottom: auto;
    left: auto;
  }
}
.options--hide {
  opacity: 0;
  visibility: hidden;
}
@media (min-width: 48rem) {
  .options--hide {
    opacity: 1;
    visibility: visible;
  }
}
.options__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (min-width: 48rem) {
  .options__wrapper {
    justify-content: flex-end;
  }
}
.options__bitcoin {
  display: flex;
  flex-direction: row;
  align-items: center;
  order: 2;
}
@media (min-width: 48rem) {
  .options__bitcoin {
    order: 1;
  }
}
.options__bitcoin--opened .options__bitcoin-address {
  opacity: 1;
  visibility: visible;
}
.options__bitcoin--opened .options__bitcoin-icon {
  opacity: 1;
}
.options__bitcoin-address {
  position: absolute;
  right: -5px;
  top: -30px;
  opacity: 0;
  visibility: hidden;
  margin-right: 10px;
  color: #ffffff;
  font-weight: 900;
  font-size: 12px;
}
@media (min-width: 48rem) {
  .options__bitcoin-address {
    position: relative;
    top: auto;
    right: auto;
    padding-right: 10px;
    font-size: 14px;
  }
}
.options__bitcoin-icon-wrapper {
  margin: 0;
  padding: 0;
  line-height: 1;
  appearance: none;
  border: none;
  background: none;
  font-family: inherit;
  outline: 0;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background: #000000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.options__bitcoin-icon-wrapper[disabled] {
  opacity: 0.5;
}
.options__bitcoin-icon-wrapper:hover .options__bitcoin-icon {
  opacity: 1;
}
.options__bitcoin-icon {
  width: 25px;
  height: 25px;
  opacity: 0.6;
}
.options__github {
  width: 40px;
  height: 40px;
  background: #000000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  order: 1;
}
@media (min-width: 48rem) {
  .options__github {
    order: 2;
    margin-left: 10px;
  }
}
.options__github:hover .options__github-icon {
  opacity: 1;
}
.options__github-icon {
  width: 25px;
  height: 25px;
  opacity: 0.6;
}

.qrcode {
  width: 72px;
  height: 72px;
  z-index: 10;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
.qrcode:hover .qrcode__img {
  filter: brightness(150%);
}
.qrcode__container {
  position: relative;
  height: 72px;
  width: 72px;
  border-radius: 50%;
  background: #000000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #212020;
  cursor: pointer;
}
.qrcode__container--opened {
  width: 300px;
  height: 300px;
}
.qrcode__modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(41, 41, 41, 0.9);
}
.qrcode--opened {
  position: absolute;
  height: 100vh;
  width: 100%;
}
.qrcode__img {
  width: 30px;
  height: 30px;
  background-color: #979797;
  border-radius: 2px;
  box-shadow: 0px 2px 2px 0px #838383;
}
.qrcode__img:hover {
  cursor: pointer;
}
.qrcode__img--opened {
  box-shadow: none;
  background-color: transparent;
  width: 32px;
  height: 32px;
  position: absolute;
  z-index: 10;
  right: 0;
}