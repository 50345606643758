$submit-icon-size: 40px;

// Submit
.submit {
  height: $icon-size;
  width: $icon-size;
  border-radius: 50%;
  background: $background-primary;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: $border;
  cursor: pointer;
  z-index: 3;

  @include larger-than(tablet) {
    border-radius: 50%;
  }

  &:hover {
    .submit__asset {
      opacity: 1;
    }
  }

  &__asset {
    width: 60%;
    opacity: 0.6;
  }

  &__close {
    border-radius: unset;
    border: 0;
    height: $submit-icon-size;
    width: $submit-icon-size;
    top: $base-spacing / 2;

    @include larger-than(mobile) {
      margin-top: 4px;
      border-radius: 50%;
      margin-right: 1px;
    }
  }
}
