// Variables

$input-container-width: 540px;
$border: 1px solid $border-color;
$border-radius: 4px;
$icon-size: 72px;
$base-spacing: 20px;
$transition: all 0.5s cubic-bezier(0.215, 0.610, 0.355, 1);

$font-family: source-code-pro, Menlo, Monaco, Consolas,"Courier New", monospace;
$font-weight-bold: 900;
$font-weight-regular: 400;
$font-extra-small-size: 14px;
$font-small-size: 18px;
$font-base-size: 24px;
$font-medium-size: 32px;
$font-large-size: 50px;
