// QRCode
.qrcode {
  width: $icon-size;
  height: $icon-size;
  z-index: 10;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  &:hover {
    .qrcode__img {
      filter: brightness(150%);
    }
  }

  &__container {
    position: relative;
    height: $icon-size;
    width: $icon-size;
    border-radius: 50%;
    background: $background-primary;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: $border;
    cursor: pointer;

    &--opened {
      width: 300px;
      height: 300px;
    }
  }

  &__modal {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba($color: #292929, $alpha: 0.9)
  }

  &--opened {
    position: absolute;
    height: 100vh;
    width: 100%;
  }

  &__img {
    width: 30px;
    height: 30px;
    background-color: $text-tertiary;
    border-radius: 2px;
    box-shadow: 0px 2px 2px 0px $text-secondary;

    &:hover {
      cursor: pointer;
    }

    &--opened {
      box-shadow: none;
      background-color: transparent;
      width: 32px;
      height: 32px;
      position: absolute;
      z-index: 10;
      right: 0;
    }
  }
}
