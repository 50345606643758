// Logo
.logo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: $base-spacing + 5;

  @include larger-than(mobile) {
    align-items: center;
    padding: 2 * $base-spacing 0;
    padding-bottom: 2 * $base-spacing;
    margin-bottom: 2 * $base-spacing + 5;
  }

  &__title {
    color: $text-primary;
    font-size: 24px;
    font-weight: $font-weight-bold;

    @include larger-than(mobile) {
      font-size: 60px;
    }
  }

  &__subtitle {
    text-align: left;
    color: $text-tertiary;
    padding-top: $base-spacing;
    font-weight: $font-weight-bold;
    font-size: $font-extra-small-size;

    @include larger-than(mobile) {
      text-align: center;
      font-size: $font-base-size;
    }
  }

  &__subtitle-small {
    display: block;
    font-size: 12px;
    color: $text-primary;
    padding-top: 0.5 * $base-spacing;
    font-weight: $font-weight-bold;

    @include larger-than(mobile) {
      font-size: 16px;
    }
  }
}
