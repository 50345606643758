// Invoice
.invoice {
  background: $background-primary;
  border-radius: $border-radius;
  width: 100%;

  margin: 0 auto;
  padding: ($base-spacing / 2) 0;
  opacity: 0;
  visibility: hidden;
  border: $border;
  margin-bottom: 20px;

  &--opened {
    opacity: 1;
    visibility: visible;
  }

  &__item {
    padding: ($base-spacing / 2) 20px;
    display: flex;
    flex-direction: column;

    @include larger-than(mobile) {
      flex-direction: row;
    }

    &--nested {
      flex-direction: column;
    }
  }

  &__item-title {
    color: $text-secondary;
    font-weight: $font-weight-bold;
    min-width: 200px;
    padding-bottom: 5px;

    @include larger-than(mobile) {
      padding-bottom: 0;
    }
  }

  &__item-value {
    font-weight: $font-weight-bold;
    color: $text-primary;
    width: 100%;
    word-break: break-all;

    &--nested {
      padding: ($base-spacing / 2) 0;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &__nested-item {
    padding: ($base-spacing / 4) 0;
    display: flex;
    flex-direction: row;
    padding-left: $base-spacing;
  }

  &__nested-title {
    font-size: 13px;
    color: $text-secondary;
    font-weight: $font-weight-bold;
    min-width: 180px;
  }

  &__nested-value {
    font-weight: $font-weight-bold;
    color: $text-primary;
    width: 100%;
  }
}
