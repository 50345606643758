@mixin reset {
  margin: 0;
  padding: 0;
  line-height: 1;
}

@mixin reset-ul {
  @include reset;
  li {
    list-style: none;
  }
}

@mixin reset-a {
  text-decoration: none;
  color: inherit;
}

@mixin reset-button {
  @include reset;
  appearance: none;
  border: none;
  background: none;
  font-family: inherit;
  outline: 0;
  cursor: pointer;
  &[disabled] {
    opacity: 0.5;
  }
}

@mixin reset-text-input {
  @include reset;
  background: transparent;
  font-family: inherit;
  border: none;
  color: #000;
  resize: none;
  &:focus {
    outline: none;
  }
  &::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
  &::-moz-placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
  &:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
  &:-moz-placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
}

@mixin reset-iframe {
  @include reset;
  border: none;
  width: 100%;
}

@mixin reset-select {
  appearance: none;
  border: none;
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-focus-ring-color: rgba(255, 255, 255, 0);
  outline: none;
}
